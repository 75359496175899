import Vue from 'vue'

export default {
  SET (state, payload) {
    state.classroom = Object.assign({}, state.classroom, payload) //precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.classrooms = payload
  },
  DELETE (state, payload) {
    if (state.classroom.id && state.classroom.id === payload) state.classroom = {}
    const idx1 = state.classrooms.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.classrooms.splice(idx1, 1) //precisa ser assim para manter a reatividade
  },
  STORE (state, payload) {
    state.classrooms.push(payload) //precisa ser assim para manter a reatividade
    state.classrooms.sort((a, b) => { return a.class_name.localeCompare(b.class_name) }) // Ordena por nome
  },
  UPDATE (state, payload) {
    const idx = state.classrooms.findIndex(o => o.id === payload.id)
    if (idx >= 0) {
      Vue.set(state.classrooms, idx, payload)
      state.classrooms.sort((a, b) => { return a.class_name.localeCompare(b.class_name) }) // Ordena por nome
    }
  }
}
