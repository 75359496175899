import axios from '@/axios.js'

export default {
  fetchAll ({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.classrooms.length) {
        axios.get(`${process.env.VUE_APP_API_URL}/classrooms`)
          .then((response) => {
            commit('REFRESH', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      } else {
        resolve(state.classrooms)
      }
    })
  },
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/classroom`, payload)
        .then((resp) => {
          commit('STORE', resp.data)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/classroom/${payload.id}`, payload)
        .then((resp) => {
          commit('UPDATE', resp.data)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/classroom/${id}`)
        .then((response) => {
          commit('DELETE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
