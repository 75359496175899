import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/theoreticalClass`, payload)
        .then((response) => {
          commit('ADD_TURMA_SCHEDULES', response.data)    // ESTOU RETORNANDO UM ARRAY DE SCHEDULES
          commit('FILL_SELECTED_DAYS')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  storeStudentSchedule ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/theoreticalClass/student`, payload)
        .then((response) => {
          commit('ADD_TURMA_SCHEDULES', response.data)    // ESTOU RETORNANDO UM ARRAY DE SCHEDULES
          commit('FILL_SELECTED_DAYS')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateMany (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/updateMany`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/get`, payload)
        .then((response) => {
          commit('SET_TURMA_SCHEDULES', response.data.turmaSchedule)
          commit('SET_EMPLOYEE_SCHEDULES', response.data.employeeSchedule)
          commit('SET_CLASSROOM_SCHEDULES', response.data.classroomSchedule)
          commit('SET_HOLIDAY_SCHEDULES', response.data.holidaySchedules)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFilterByStudent ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/get/byStudent`, payload)
        .then((response) => {
          commit('SET_TURMA_SCHEDULES', response.data.turmaSchedule)
          commit('SET_EMPLOYEE_SCHEDULES', response.data.employeeSchedule)
          commit('SET_CLASSROOM_SCHEDULES', response.data.classroomSchedule)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/theoreticalClass/delete`, payload)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // MÓDULO DE ALUNOS
  fetchStudentSchedules ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/student`, payload)
        .then((response) => {
          commit('SET_STUDENT_LIST_SCHEDULES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}
