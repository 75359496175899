import state from './moduleScheduleTheoreticalClassState.js'
import mutations from './moduleScheduleTheoreticalClassMutations.js'
import actions from './moduleScheduleTheoreticalClassActions.js'
import getters from './moduleScheduleTheoreticalClassGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

