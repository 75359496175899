import state from './moduleClassroomState.js'
import mutations from './moduleClassroomMutations.js'
import actions from './moduleClassroomActions.js'
import getters from './moduleClassroomGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

