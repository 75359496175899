export default {
  forSelect (state) {
    const data = []
    for (const k in state.classrooms) {
      data.push({
        value: state.classrooms[k].id,
        label: `${state.classrooms[k].class_name} (${state.classrooms[k].capacity})`,
        data: state.classrooms[k]
      })
    }
    return data
  }
}
