export default {
  selectedDays: [],
  periods: [0, 1, 2], // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
  turmaSchedules: [],
  employeeSchedules: [],
  classroomSchedules: [],
  holidaySchedules: [],

  //MÓDULO DE ALUNOS
  studentListSchedules: []
}
