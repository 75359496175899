
export default {
  STORE (state, payload) {
    state.turmas.push(payload)
  },
  SET (state, payload) {
    state.turma = Object.assign({}, state.turma, payload) //precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.turmas = payload
  },
  DELETE (state, payload) {
    if (state.turma.id === payload) state.turma = {}
  }
}
