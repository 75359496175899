import state from './moduleTurmaState.js'
import mutations from './moduleTurmaMutations.js'
import actions from './moduleTurmaActions.js'
import getters from './moduleTurmaGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

