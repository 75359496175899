import Vue from 'vue'

export default {
  SET_SCHEDULE (state, payload) {
    state.schedule = Object.assign({}, state.schedule, payload) //precisa ser assim para manter a reatividade
  },
  UPDATE_SCHEDULE (state, payload) {
    const index1 = state.studentSchedules.findIndex(o => o.id === payload.id)
    const index2 = state.employeeSchedules.findIndex(o => o.id === payload.id)
    const index3 = state.classroomSchedules.findIndex(o => o.id === payload.id)
    if (index1 >= 0) Vue.set(state.studentSchedules, index1, payload)
    if (index2 >= 0) Vue.set(state.employeeSchedules, index2, payload)
    if (index3 >= 0) Vue.set(state.classroomSchedules, index2, payload)

    state.selectedDays.map(e => {
      const idx = e.studentSchedule.findIndex(o => o.id === payload.id)
      if (idx >= 0) Vue.set(e.studentSchedule, idx, payload)

      const idx2 = e.employeeSchedule.findIndex(o => o.id === payload.id)
      if (idx2 >= 0) Vue.set(e.employeeSchedule, idx2, payload)

      const idx3 = e.classroomSchedule.findIndex(o => o.id === payload.id)
      if (idx3 >= 0) Vue.set(e.classroomSchedule, idx3, payload)
    })
  },

  //AQUI NAO UTILIZO O RETORNO DA API, NESTE CASO FAÇO A MUDANÇA ANTES DE ENVIAR PARA A API
  UPDATE_MASSIVE_SCHEDULES (state, payload) {
    payload.schedules.map(a => {
      const index1 = state.turmaSchedules.findIndex(o => o.id === a.id)
      const index2 = state.employeeSchedules.findIndex(o => o.id === a.id)
      const index3 = state.classroomSchedules.findIndex(o => o.id === a.id)
      if (index1 >= 0) Vue.set(state.turmaSchedules, index1, Object.assign({}, a, payload.changes))
      if (index2 >= 0) Vue.set(state.employeeSchedules, index2, Object.assign({}, a, payload.changes))
      if (index3 >= 0) Vue.set(state.classroomSchedules, index3, Object.assign({}, a, payload.changes))

      state.selectedDays.map(e => {
        const idx =  e.turmaSchedule.findIndex(o => (o.id && o.id === a.id) || (!o.id && o.start_time === a.start_time && o.start_date === a.start_date))
        const idx1 = e.employeeSchedule.findIndex(o => (o.id && o.id === a.id) || (!o.id && o.start_time === a.start_time && o.start_date === a.start_date))
        const idx2 = e.classroomSchedule.findIndex(o => (o.id && o.id === a.id) || (!o.id && o.start_time === a.start_time && o.start_date === a.start_date))
        if (idx >= 0)  Vue.set(e.turmaSchedule, idx, Object.assign({}, a, payload.changes))
        if (idx1 >= 0) Vue.set(e.employeeSchedule, idx, Object.assign({}, a, payload.changes))
        if (idx2 >= 0) Vue.set(e.classroomSchedule, idx, Object.assign({}, a, payload.changes))
      })
    })
  },

  SET_SELECTED_DAYS (state, payload) {
    state.selectedDays = payload.sort((a, b) => {               //ORDENA AS DATAS
      return new Date(a.date) - new Date(b.date)
    })
  },
  ADD_TURMA_SCHEDULES (state, payload) {
    state.turmaSchedules = state.turmaSchedules.concat(payload)
    state.selectedDays.map(e => {
      e.turmaSchedule = e.turmaSchedule.concat(payload)
    })
  },
  UPDATE_PERIOD (state, payload) {
    state.periods = payload
  },

  SET_TURMA_SCHEDULES (state, payload) {
    state.turmaSchedules = payload //precisa ser assim para manter a reatividade
  },
  SET_EMPLOYEE_SCHEDULES (state, payload) {
    state.employeeSchedules = payload //precisa ser assim para manter a reatividade
  },
  SET_CLASSROOM_SCHEDULES (state, payload) {
    state.classroomSchedules = payload //precisa ser assim para manter a reatividade
  },
  SET_HOLIDAY_SCHEDULES (state, payload) {
    state.holidaySchedules = payload //precisa ser assim para manter a reatividade
  },

  // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
  FILL_SELECTED_DAYS (state) {
    state.selectedDays.map(selectedDay => {
      selectedDay.turmaSchedule.splice(0) // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.employeeSchedule.splice(0) // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.classroomSchedule.splice(0) // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.holidaySchedule.splice(0)   // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS

      state.turmaSchedules.map(schedule => {      // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.start_date.slice(0, 10)) {
          selectedDay.turmaSchedule.push(schedule)
        }
      })
      state.employeeSchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.start_date.slice(0, 10)) {
          selectedDay.employeeSchedule.push(schedule)
        }
      })
      state.classroomSchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.start_date.slice(0, 10)) {
          selectedDay.classroomSchedule.push(schedule)
        }
      })
      state.holidaySchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.date.slice(0, 10)) {
          selectedDay.holidaySchedule.push(schedule)
        }
      })
    })
  },

  //EXCLUI DO ARRAY
  DELETE (state, payload) {
    payload.map(e => {
      const idx1 = state.turmaSchedules.findIndex(o => o.id === e)
      const idx2 = state.employeeSchedules.findIndex(o => o.id === e)
      const idx3 = state.classroomSchedules.findIndex(o => o.id === e)
      if (idx1 >= 0) state.turmaSchedules.splice(idx1, 1)
      if (idx2 >= 0) state.employeeSchedules.splice(idx2, 1)
      if (idx3 >= 0) state.classroomSchedules.splice(idx3, 1)

      state.selectedDays.map(sd => {
        const idx_ = sd.turmaSchedule.findIndex(o => o.id === e)
        const idx_1 = sd.employeeSchedule.findIndex(o => o.id === e)
        const idx_2 = sd.classroomSchedule.findIndex(o => o.id === e)
        if (idx_ >= 0) sd.turmaSchedule.splice(idx_, 1)
        if (idx_1 >= 0) sd.employeeSchedule.splice(idx_1, 1)
        if (idx_2 >= 0) sd.classroomSchedule.splice(idx_2, 1)
      })
    })
  },

  // MÓDULO DE ALUNOS
  SET_STUDENT_LIST_SCHEDULES (state, payload) {
    state.studentListSchedules = payload
  },
  DELETE_STUDENT_LIST_SCHEDULES (state, payload) {
    payload.map(sd => {
      const idx = state.studentListSchedules.data.findIndex(o => o.id === sd.id)
      if (idx >= 0) {
        state.studentListSchedules.data.splice(idx, 1) //precisa ser assim para manter a reatividade
        state.studentListSchedules.total = state.studentListSchedules.total - 1
      }
    })
  },
  ADD_STUDENT_LIST_SCHEDULES (state, payload) {
    state.studentListSchedules = state.studentListSchedules.concat(payload)
    state.studentListSchedules.total = state.studentListSchedules.total + payload.length
  },
  UPDATE_STUDENT_LIST_SCHEDULES (state, payload) {
    const idx = state.studentListSchedules.data.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.studentListSchedules.data, idx, payload)
  }
}
